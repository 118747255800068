type Config = {
  ENV_URL_BFF: string;
  ENV_URL_BCK: string;
  IAL_BASE_URL: string;
  IAL_REPORTS_API: string;
  IAL_USER_PREFERENCES_API: string;
  IAL_UPLOADS_API: string;
  IAL_BASE_API_URL: string;
  IAL_SHO_API: string;
  IAL_PROBLEM_SOLVING_API: string;
  IAL_MEETINGS_API: string;
  version: string;
};

const config: Config = {} as Config;

if (process.env.ENV === "dev") {
  config.ENV_URL_BFF = "https://vpo-dev.ab-inbev.com/api-bff";
  config.ENV_URL_BCK = "https://vpo-dev.ab-inbev.com/api";
  config.IAL_BASE_URL = "https://interactionlog-dev.ab-inbev.com";
  config.IAL_BASE_API_URL = "https://api-interactionlog-dev.ab-inbev.com";
  config.IAL_REPORTS_API = `${config.IAL_BASE_API_URL}/dws-reports`;
  config.IAL_USER_PREFERENCES_API = `${config.IAL_BASE_API_URL}/user-preferences`;
  config.IAL_UPLOADS_API = `${config.IAL_BASE_API_URL}/upload-files`;
  config.IAL_SHO_API = `${config.IAL_BASE_API_URL}/shift-handover`;
  config.IAL_PROBLEM_SOLVING_API = `${config.IAL_BASE_API_URL}/problem-solving`;
  config.IAL_MEETINGS_API = `${config.IAL_BASE_API_URL}/meetings`;
} else if (process.env.ENV === "qa") {
  config.ENV_URL_BFF = "https://vpo-qa.ab-inbev.com/api-bff";
  config.ENV_URL_BCK = "https://vpo-qa.ab-inbev.com/api";
  config.IAL_BASE_URL = "https://interactionlog-qa.ab-inbev.com";
  config.IAL_BASE_API_URL = "https://api-interactionlog-qa.ab-inbev.com";
  config.IAL_REPORTS_API = `${config.IAL_BASE_API_URL}/dws-reports`;
  config.IAL_USER_PREFERENCES_API = `${config.IAL_BASE_API_URL}/user-preferences`;
  config.IAL_UPLOADS_API = `${config.IAL_BASE_API_URL}/upload-files`;
  config.IAL_SHO_API = `${config.IAL_BASE_API_URL}/shift-handover`;
  config.IAL_PROBLEM_SOLVING_API = `${config.IAL_BASE_API_URL}/problem-solving`;
  config.IAL_MEETINGS_API = `${config.IAL_BASE_API_URL}/meetings`;
} else if (process.env.ENV === "prod") {
  config.ENV_URL_BFF = "https://vpo.ab-inbev.com/api-bff";
  config.ENV_URL_BCK = "https://vpo.ab-inbev.com/api";
  config.IAL_BASE_URL = "https://interactionlog.ab-inbev.com";
  config.IAL_BASE_API_URL = "https://api-interactionlog.ab-inbev.com";
  config.IAL_REPORTS_API = `${config.IAL_BASE_API_URL}/dws-reports`;
  config.IAL_USER_PREFERENCES_API = `${config.IAL_BASE_API_URL}/user-preferences`;
  config.IAL_UPLOADS_API = `${config.IAL_BASE_API_URL}/upload-files`;
  config.IAL_SHO_API = `${config.IAL_BASE_API_URL}/shift-handover`;
  config.IAL_PROBLEM_SOLVING_API = `${config.IAL_BASE_API_URL}/problem-solving`;
  config.IAL_MEETINGS_API = `${config.IAL_BASE_API_URL}/meetings`;
} else {
  config.ENV_URL_BFF = "https://vpo-dev.ab-inbev.com/api-bff";
  config.ENV_URL_BCK = "https://vpo-dev.ab-inbev.com/api";
  config.IAL_BASE_URL = "https://interactionlog-dev.ab-inbev.com";
  config.IAL_REPORTS_API = "http://localhost:49460";
  config.IAL_BASE_API_URL = "https://api-interactionlog-dev.ab-inbev.com";
  config.IAL_USER_PREFERENCES_API = "http://localhost:2002";
  config.IAL_UPLOADS_API = "http://localhost:2003";
  config.IAL_SHO_API = "http://localhost:2009";
  config.IAL_PROBLEM_SOLVING_API = "http://localhost:3000";
  config.IAL_MEETINGS_API = "http://localhost:2345";
}

config.version = "0.1.0";

export { config };
