import { getAccount, useSilentAuthLogin } from "../dws-auth";
import React, { ReactElement, ReactNode } from "react";
import * as msal from "@azure/msal-browser";
import "./AuthenticatedSPA.css";

interface AuthenticatedSPAProps {
  children: ReactNode;
  clientId: string;
}

interface AuthenticatedChildProps {
  authenticationData: msal.AuthenticationResult;
}

export function AuthenticatedSPA({
  children,
  clientId,
}: Readonly<AuthenticatedSPAProps>): ReactElement {
  const account = getAccount();
  const scopes = [`openid email profile ${clientId}/.default`];
  const res = useSilentAuthLogin({ account, scopes });
  const { isLoading, isAuthenticated, error, authenticationData } = res;

  if (isLoading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;
  if (!isAuthenticated) return <NotAuthenticatedComponent />;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        authenticationData,
      } as React.PropsWithChildren<AuthenticatedChildProps>);
    }
    return child;
  });

  return <>{childrenWithProps}</>;
}

function ErrorComponent(): ReactElement {
  return <div className="error-display">Something went wrong</div>;
}

function LoadingComponent(): ReactElement {
  return <div className="authentication-loader">Authenticating...</div>;
}

function NotAuthenticatedComponent(): ReactElement {
  return <div className="unauthorized-display">Not authenticated</div>;
}
