import { Row, Col } from "@dws/dws-styleguide";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import LoginTemplate from "./LoginTemplate";
import dwsLogo from "../../public/img/dws-logo-large.svg";
import abiLogo from "../../public/img/abi-logo.png";
import { CONSTANTS } from "../constants";

const clientId: string = CONSTANTS.AUTH.CLIENT_ID;
const authority = `https://login.microsoftonline.com/${CONSTANTS.AUTH.TENANT_ID}/`;
const redirectUri = "/";
const postLogoutRedirectUri = "/";

const configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
    postLogoutRedirectUri,
  },
  cache: {},
  system: {},
};
const pca = new PublicClientApplication(configuration);

type LoginPageProps = Record<string, unknown> & { children?: React.ReactNode };
export function LoginPage(_: LoginPageProps) {
 
  return (
    <MsalProvider instance={pca} >
      <Row className="container-login">
        <Col m={6} className="div-header hide-on-small-only">
          <img src={dwsLogo} alt="DWS Logo" />
        </Col>
        <Col m={6} s={12} className="div-body">
          <div className="login-card">
            <img src={abiLogo} alt="ABI Logo" />
            <LoginTemplate />
          </div>
        </Col>
      </Row>
    </MsalProvider >
  );
}

export { pca };
