import { AccountInfo } from "@azure/msal-common";

const saveIdToken = (idToken: string | null) => {
  if (idToken) {
    localStorage.setItem("idToken", idToken);
  }
};

const getIdToken = (): string | null => {
  return localStorage.getItem("idToken");
};

const saveAccessToken = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  }
};

const getAccessToken = (): string | null => {
  return localStorage.getItem("accessToken");
};

const saveAccount = (account: object | null) => {
  if (account) {
    localStorage.setItem("account", JSON.stringify(account));
  }
};

const getAccount = (): AccountInfo => {
  const accountString = localStorage.getItem("account");
  return accountString ? JSON.parse(accountString) : null;
};

const saveUser = (user: object | null) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
};

const getUser = (): object | null => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

const getHeaders = () => {
  const accessToken = getAccessToken();
  return {
    headers: {
      "Accept-Language": localStorage.getItem("i18nextLng") || "",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };
};

const deleteCredentials = () => {
  localStorage.removeItem("account");
  localStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("i18nextLng");
  localStorage.removeItem("user");
};

export {
  deleteCredentials,
  getAccessToken,
  getAccount,
  getHeaders,
  getIdToken,
  saveAccessToken,
  saveAccount,
  saveIdToken,
  saveUser,
  getUser,
};
