import { useEffect, useState } from "react";
import * as msal from "@azure/msal-browser";
import { CONSTANTS } from "../constants";

interface UseSilentAuthLoginProps {
  account: msal.AccountInfo;
  scopes: string[];
}

interface UseSilentAuthLoginResult {
  isAuthenticated: boolean;
  authenticationData?: msal.AuthenticationResult;
  isLoading: boolean;
  error?: unknown;
}

export function useSilentAuthLogin({
  account,
  scopes,
}: UseSilentAuthLoginProps): UseSilentAuthLoginResult {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authenticationData, setAuthenticationData] = useState<
    msal.AuthenticationResult | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  function onSuccess(data: msal.AuthenticationResult) {
    setIsAuthenticated(true);
    setAuthenticationData(data);
    setIsLoading(false);
  }

  function onError(error: unknown) {
    setIsAuthenticated(false);
    setAuthenticationData(undefined);
    setError(error);
    setIsLoading(false);
    window.location.href = `${window.location.origin}/`;
  }

  useEffect(() => {
    const func = () => silentLogin(account, scopes, onSuccess, onError);

    const timeOptions = {
      fortyfiveMinutes: 45 * 60 * 1000,
      tenMinutes: 10 * 60 * 1000,
      tenSeconds: 10 * 1000,
    };

    const interval = setInterval(() => {
      func().catch((error) => onError(error));
    }, timeOptions.tenMinutes);
    func();

    return () => clearInterval(interval);
  }, [account?.idToken]);

  return { isAuthenticated, authenticationData, isLoading, error };
}

async function silentLogin(
  account: msal.AccountInfo,
  scopes: string[],
  onSuccess: (data: msal.AuthenticationResult) => void,
  onError: (error: unknown) => void
) {
  const clientId = CONSTANTS.AUTH.CLIENT_ID;
  const tenantId = CONSTANTS.AUTH.TENANT_ID;
  const authority = `https://login.microsoftonline.com/${tenantId}`;
  const redirectUri = window.location.origin;
  try {
    const msalConfig: msal.Configuration = {
      auth: { clientId, authority, redirectUri },
    };

    const request: msal.SilentRequest = { account, scopes, forceRefresh: true };
    const myMSALObj = new msal.PublicClientApplication(msalConfig);
    await myMSALObj.initialize();
    const token = await myMSALObj.acquireTokenSilent(request);
    return onSuccess(token);
  } catch (error) {
    return onError(error);
  }
}
