import React, { useState } from "react";
import { deleteCredentials, getAccount } from "../hooks/loginInfo";
import { useTranslation } from "@dws/dws-profile";
import { AccountInfo } from "@azure/msal-common";
import { Button, SideNavItem, DWSModal } from "@dws/dws-styleguide";
import { pca } from "./LoginPage";
import { datadogRum } from '@datadog/browser-rum';

function LogoutButton(): React.ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleLogout = async () => {
    const account: AccountInfo | null = getAccount();
    if (account) {
      const payload = { account };
      deleteCredentials();
      try {
        await pca.logoutRedirect(payload);
        deleteCredentials();
        datadogRum.clearUser();
      } catch (e) {
        console.log("handleLogout_error", e);
      }
    }
  };

  return (
    <div className="container-logout-modal">
      <span
        className="modal-trigger btn-icon-transparent logout-modal"
        onClick={() => setIsOpen(true)}>
        <SideNavItem href="#">{t("general.logout")}</SideNavItem>
      </span>
      <DWSModal
        className={`delete-modal`}
        header={t("general.logout_header")}
        visible={isOpen}
        onHide={() => setIsOpen(false)}
        modalContent={t("general.logout_body")}
        dismissible={true}
        showCloseIcon={true}
        footer={
          <>
            <div className="div-buttons">
              <Button
                className="btn btn-darkest"
                modal="close"
                onClick={() => setIsOpen(false)}>
                <div>
                  <span>{t("general.cancel")}</span>
                </div>
              </Button>
              <Button className="btn btn-purple" onClick={() => handleLogout()}>
                <div>
                  <span>{t("general.logout")}</span>
                </div>
              </Button>
            </div>
          </>
        }></DWSModal>
    </div>
  );
}

export { LogoutButton };
