import Hotjar from '@hotjar/browser';

const siteId = 3447614;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// Initializing with `debug` option:
const hotjarConfig: any = {
  debug: true,
};

Hotjar.init(siteId, hotjarVersion, hotjarConfig);
