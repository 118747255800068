import React, { useEffect, useState } from "react";
import { Button, Loading } from "@dws/dws-styleguide";
import { useMsal } from "@azure/msal-react";
import { saveAccessToken, saveIdToken, saveAccount } from "../hooks/loginInfo";
import { Redirect } from "@reach/router";
import { CONSTANTS } from "../constants";
import { datadogRum } from '@datadog/browser-rum';

const scopes = [
  "openid",
  "profile",
  "email",
  `${CONSTANTS.AUTH.CLIENT_ID}/.default`,
];

const LoginTemplate: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [redirectNow, setRedirectNow] = useState<boolean>(false);

  useEffect(() => {
    if (accounts[0]) {
      saveLogin();
    }
  }, [accounts]);

  const saveLogin = async () => {
    const authRequest = {
      account: accounts[0],
      scopes,
    };
    try {
      const response = await instance.acquireTokenSilent(authRequest);
      if (response?.idToken) {
        saveAccessToken(response.accessToken);
        saveIdToken(response.idToken);
        saveAccount(response.account);
        handleDatadog(response.uniqueId);
        setRedirectNow(true);
      }
    } catch (e) {
      console.log("forceLogin_error", e);
    }
  };

  const login = () => {
    try {
      instance.acquireTokenRedirect({ scopes });
    } catch (e) {
      console.log("login_error", e);
    }
  };

  const handleDatadog = (userIdToken: string) => {
    datadogRum.clearUser();
  
    const filtersJSON = localStorage.getItem("filters");
    const filters = filtersJSON ? JSON.parse(filtersJSON) : null;
  
    datadogRum.setUser({
      id: userIdToken,
      locationId: filters?.location?.locationId || "",
      locationName: filters?.location?.locationName || "",
    });
  
    datadogRum.addAction('Login');
  };

  return (
    <>
      {redirectNow && <Redirect to={"/main"} />}
      {inProgress !== "none" ? (
        <Loading size="small" />
      ) : (
        <Button onClick={login} className="btn-login">
          Login with SSO
        </Button>
      )}
    </>
  );
};

export default LoginTemplate;
